import React from "react";
import { H1, P } from "../Sections/SectionStyled";
import { SubPageWrapper, H2, Underline, Bold } from "./SubPageStyled";

const Qualifikation: React.FC = () => (
  <SubPageWrapper>
    <H1>Meine Qualifikationen - zusammengefasst</H1>
    <H2>Heilpraktiker für Psychotherapie</H2>
    <P>
      Erlaubnis durch Bezirksamt (Gesundheitsamt) Lichtenberg von Berlin am
      22.01.2021
    </P>
    <P>
      <Underline>Zuständige Behörde</Underline>
      <br />
      <Bold>
        Landesamt für Gesundheit und Soziales Berlin <br />
      </Bold>
      Fehrbelliner Platz 1 <br />
      10707 Berlin <br />
      Anmeldung ab/seit 17.02.2022
    </P>
    <H2>Coaching und Persönlichkeitsentwicklung</H2>
    <P>
      <Underline>Ausbildung bei</Underline> <br />
      <Bold>Arche Medica Berlin</Bold> <br />
      Mathias Onnen / Christina Klähn-Schmidt <br />
      Handjerystraße 22 <br />
      12159 Berlin <br />
      Verbandsschule des Bundes Deutscher Heilpraktiker (BDH)
    </P>
    <H2>Ressourcenorientierte Psychotherapie</H2>
    <P>
      <Underline>Ausbildung bei</Underline> <br />
      <Bold>Arche Medica Berlin</Bold> <br />
      Vera Dreher/ Christina Klähn-Schmidt
      <br />
      Handjerystrasß 22
      <br />
      12159 Berlin
      <br />
      Verbandsschule des Bundes deutscher Heilpraktiker (BDH).{" "}
    </P>
    <H2>Hypnotherapeut</H2>
    <P>NGH (National Guild of Hypnotists)</P>
    <P>
      <Underline>Ausbildung bei</Underline> <br />
      <Bold>NLP Zentrum</Bold> <br />
      Matthias Bär <br />
      Uhlandstraße 85 <br />
      10717 Berlin <br />
      Lehrinstitut der DVNLP (Deutscher Verband für Neuro-Linguistisches
      Programmieren e.V.)
    </P>
    <H2>Ego-State Coaching und Therapie</H2>
    <P>
      <Underline>Ausbildung bei</Underline> <br />
      <Bold>Neures-Akademie</Bold> <br />
      Carsten Gramatke <br />
      Brandenburgische Straße 78 <br />
      10713 Berlin <br />
      zertifiziert nach ICI (International Association of Coaching Institutes)
    </P>
  </SubPageWrapper>
);

export default Qualifikation;
